import backendKey from "./backendKey";
import { jsdos_games } from "@/utils/js-dos";
import { emularity_games } from "@/utils/emularity";
import { hasSave as hasEmularitySave } from "@/utils/emularity";
import isElectron from "@/utils/isElectron";

export function testLocalStorage(identifier) {
  try {
    if (window?.localStorage?.getItem) {
      const localStorageBackend = window.localStorage.getItem(
        backendKey(identifier)
      );

      // Use user's preference if it exists
      if (localStorageBackend) {
        return localStorageBackend;
      }

      return undefined;
    }
  } catch (e) {
    return undefined;
  }
}

export function testiOS() {
  // if iOS, use jsdos
  const test = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  return test ? "jsdos" : undefined;
}

export function testDefault(identifier) {
  if (jsdos_games.includes(identifier)) {
    return "jsdos"
  }
  if (emularity_games.includes(identifier)) {
    return "emularity"
  }
  return undefined;
}

export async function testEmularitySave(identifier) {
  if (await hasEmularitySave(identifier)) {
    return "emularity";
  }
  return undefined;
}

export function testElectron() {
  if (isElectron) {
    return 'native_dosbox';
  }
  return undefined;
}
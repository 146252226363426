<template>
  <a v-b-modal.modal-export class="card-link save-control">
    <span>导出存档</span>
    <b-modal
      v-model="modal_show"
      hide-footer
      id="modal-export"
      title="导出存档"
    >
      <JsDosExportSaveModal v-if="modal_show && dos_backend === 'jsdos'" />
      <EmularityExportSaveModal
        v-if="modal_show && dos_backend === 'emularity'"
      />
    </b-modal>
  </a>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";

export default {
  name: "ExportSave",
  props: ["dos_backend"],
  components: {
    JsDosExportSaveModal: () =>
      import(
        /* webpackChunkName: "ExportSaveModal" */ "./JsDosExportSaveModal.vue"
      ),
    EmularityExportSaveModal: () =>
      import(
        /* webpackChunkName: "ExportSaveModal" */ "./EmularityExportSaveModal.vue"
      ),
    BModal,
  },
  directives: { "b-modal": VBModal },
  data: function () {
    return {
      modal_show: false,
    };
  },
};
</script>
<template>
  <b-card class="mb-3 ads" id="advertise" no-body v-if="show">
    <b-card-body>
      <b-card-title title="广告" title-tag="h5"></b-card-title>
      <b-card-text class="small text-muted">
        服务器花销太大，添加广告补贴服务器费用，
        <a
          role="button"
          class="card-link"
          onclick="document.getElementById('advertise').remove();"
        >
          无广告版本
        </a>
      </b-card-text>
    </b-card-body>
    <Adsense
      data-ad-client="ca-pub-7592189052095678"
      data-ad-slot="5474451280"
      data-ad-format="auto"
      data-full-width-responsive="yes"
      is-new-ads-code="yes"
    ></Adsense>
  </b-card>
</template>

<script>
import isElectron from "@/utils/isElectron";

import { no_ads } from "@/utils/constants";

export default {
  name: "SidebarAd",
  props: ["identifier"],
  computed: {
    show: function () {
      return !no_ads.includes(this.identifier) && !isElectron;
    },
  },
};
</script>

<template>
  <b-card class="mb-3" title-tag="h5" title="引擎">
    <b-card-text>
      目前游戏引擎为：<code>{{ backend_display.name }}</code>
      <b-badge class="ml-1" :variant="backend_display.badge_variant">
        {{ backend_display.badge }}
      </b-badge>
    </b-card-text>
    <small>
      <a
        @click="changeBackend"
        tabindex="0"
        role="button"
        class="card-link"
      >
        切换引擎
      </a>
      <router-link
        class="card-link"
        :to="{ name: 'wikiBackend' }"
        target="_blank"
      >
        引擎对比
      </router-link>
    </small>
  </b-card>
</template>

<script>
import { BBadge } from "bootstrap-vue";
import { setBackend } from "@/utils/backend";

export default {
  name: "BackendCard",
  props: ["dos_backend", "identifier"],
  inject: ["jsdos_save"],
  components: {
    BBadge,
  },
  computed: {
    backend_display: function () {
      const display_map = {
        emularity: {
          name: "em-dosbox",
          badge: "old",
          badge_variant: "secondary",
        },
        jsdos: {
          name: "js-dos",
          badge: "new",
          badge_variant: "success",
        },
        native_dosbox: {
          name: "native-dosbox",
          badge: "best",
          badge_variant: "success",
        },
      };
      return display_map[this.dos_backend];
    },
  },
  methods: {
    changeBackend: async function () {
      // save first
      if (this.dos_backend === "jsdos") {
        console.log("Save before switch game backend");
        await this.jsdos_save();
      }

      const backend_after =
        this.dos_backend === "jsdos" ? "emularity" : "jsdos";

      setBackend(this.identifier, backend_after);

      location.reload();
    },
  },
};
</script>

<template>
  <b-card class="mb-3" title-tag="h5" title="存档">
    <b-card-text>
      请直接在游戏中存档{{
        dos_backend === "jsdos" ? "，并点击控制栏中的保存进度按钮" : ""
      }}。请勿清除浏览器缓存。
    </b-card-text>
    <small>
      <export-save :dos_backend="dos_backend" />
      <import-save />
    </small>
  </b-card>
</template>

<script>
import ExportSave from "./ExportSave.vue";
import ImportSave from "./ImportSave.vue";

export default {
  name: "SavesCard",
  props: ["save_key", "dos_backend"],
  components: {
    ExportSave,
    ImportSave,
  },
  provide: function () {
    return {
      save_key: this.save_key,
    };
  },
};
</script>
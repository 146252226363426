<template>
  <a v-b-modal.modal-import class="card-link save-control">
    <span>导入存档</span>
    <b-modal
      v-model="modal_show"
      id="modal-import"
      title="导入存档"
      hide-footer
    >
      <import-save-modal v-if="modal_show" />
    </b-modal>
  </a>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";

export default {
  name: "ImportSave",
  components: {
    ImportSaveModal: () =>
      import(/* webpackChunkName: "ImportSaveModal" */ "./ImportSaveModal.vue"),
    BModal,
  },
directives: { 'b-modal': VBModal },
  data: function () {
    return {
      modal_show: false,
    };
  },
};
</script>
<template>
  <b-card class="mb-3" v-if="'keymaps' in game_info" title-tag="h5" title="操作">
    <span v-for="(value, key) in game_info.keymaps" :key="key">
      <kbd class="mr-2">{{ key }}</kbd>{{ value }}<br />
    </span>
  </b-card>
</template>

<script>
export default {
  name: "KeymapsCard",
  props: ["game_info"],
};
</script>

import * as backendTest from "./backendTest";
import { logger } from "./logger";

export default async function (identifier) {
  try {
    const testLocalStorage = backendTest.testLocalStorage(identifier);
    if (testLocalStorage) {
      logger.log(`Selecting Backend: Using localStorage ${testLocalStorage}`);
      return testLocalStorage;
    }

    const testElectron = backendTest.testElectron();
    if (testElectron) {
      logger.log(`Selecting Backend: Using Electron ${testElectron}`);
      return testElectron;
    }

    const testiOS = backendTest.testiOS();
    if (testiOS) {
      logger.log(`Selecting Backend: Using iOS ${testiOS}`);
      return testiOS;
    }

    const testDefault = backendTest.testDefault(identifier);
    if (testDefault) {
      logger.log(`Selecting Backend: Using game default ${testDefault}`);
      return testDefault;
    }

    const testEmularitySave = await backendTest.testEmularitySave(identifier);
    if (testEmularitySave) {
      logger.log(
        `Selecting Backend: Using emularity save ${testEmularitySave}`
      );
      return testEmularitySave;
    }

    logger.log(`Selecting Backend: Using default jsdos`);
    return "jsdos";
  } catch (e) {
    logger.error(`Failed to decide which backend to use.`);
    return "jsdos";
  }
}

<template>
  <b-card class="mb-3" v-if="'cheats' in game_info" title-tag="h5" title="作弊">
    <span v-for="(value, key) in game_info.cheats" :key="key">
      <code class="mr-2">{{ key }}</code>{{ value }}<br />
    </span>
  </b-card>
</template>

<script>
export default {
  name: "CheatsCard",
  props: ["game_info"],
};
</script>

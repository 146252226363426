export const ProgressStatusType = {
    downloading: "downloading",
    loadingMetadata: "loadingMetadata",
    error : "error",
    success: "success",
    extracting: "extracting",
    unstarted: "unstarted"
}

export const ProgressStatusTextMap = {
    [ProgressStatusType.loadingMetadata]: "加载元数据中...",
    [ProgressStatusType.error]: "下载出错，请重试",
    [ProgressStatusType.success]: "下载成功",
    [ProgressStatusType.extracting]: "解压中",
}

export const ProgressStatusBarVariantMap ={
    [ProgressStatusType.downloading]: "primary",
    [ProgressStatusType.loadingMetadata]: "primary",
    [ProgressStatusType.error]: "danger",
    [ProgressStatusType.success]: "success",
    [ProgressStatusType.extracting]: "info",
    [ProgressStatusType.unstarted]: "secondary",
}
<template>
  <b-card v-if="cover_url" class="mb-3" no-body>
    <b-card-img
      :top="true"
      :src="cover_url"
      :alt="cover_alt"
      :srcset="cover_srcset_array"
      sizes="calc(100vw -30px)"
    />
  </b-card>
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";

export default {
  name: "CoverCard",
  props: ["game_info"],
  mixins: [game_info_mixin],
};
</script>

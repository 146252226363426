<template>
  <b-card class="mb-3" title-tag="h5" title="反馈">
    <b-card-text>
      <tr>
        <td class="nowrap">
          <b-icon-github /><span class="ml-1">GitHub</span>
        </td>
        <td class="pl-2">
          <a
            href="https://github.com/rwv/chinese-dos-games"
            target="_blank"
            rel="noopener noreferrer"
          >
            rwv/chinese-dos-games
          </a>
        </td>
      </tr>
      <tr>
        <td class="nowrap">
          <b-icon-envelope /><span class="ml-1">E-mail</span>
        </td>
        <td class="pl-2">
          <a @click="sendEmail" tabindex="0" href="javascript:void(0)">
            dos@zczc.cz
          </a>
        </td>
      </tr>
      <tr>
        <td class="nowrap">
          <b-icon-telegram />
          <span class="ml-1">Telegram</span>
        </td>
        <td class="pl-2">
          <a
            href="https://t.me/dos_zczc_cz"
            target="_blank"
            rel="noopener noreferrer"
          >
            @dos_zczc_cz
          </a>
        </td>
      </tr>
      <tr>
        <td class="nowrap">
          <BIconQQ />
          <span class="ml-1">QQ 群</span>
        </td>
        <td class="pl-2">
          <a
            href="https://qm.qq.com/cgi-bin/qm/qr?k=YcFeDdNW8__o8qOkon2DqYIEdyIUP8ui&jump_from=webapi"
            target="_blank"
            rel="noopener noreferrer"
          >
            648610120
          </a>
        </td>
      </tr>
    </b-card-text>
  </b-card>
</template>

<script>
import { BIconGithub, BIconEnvelope } from "bootstrap-vue";
import BIconTelegram from "@/components/icons/BIconTelegram";
import BIconQQ from "@/components/icons/BIconQQ";

export default {
  name: "FeedbackCard",
  methods: {
    sendEmail() {
      window.open("mailto:dos@zczc.cz", "_blank");
    },
  },
  components: {
    BIconGithub,
    BIconEnvelope,
    BIconTelegram,
    BIconQQ,
  },
};
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>
<template>
  <div style="width: 100%;" @click="$emit('click')">
    <b-progress :max="100">
      <b-progress-bar
        :value="percentage"
        :label="formattedProgressBarText"
        :variant="barVariant"
        :animated="animated"
      >
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
import filesize from "filesize";
import { BProgress, BProgressBar } from "bootstrap-vue";
import { statusType } from "@/utils/objectStorage";
import {
  ProgressStatusType,
  ProgressStatusBarVariantMap,
  ProgressStatusTextMap,
} from "./ProgressStatus";

export default {
  name: "ProgressShow",
  props: ["downloadProgress", "customText"],
  components: {
    BProgress,
    BProgressBar,
  },
  computed: {
    animated: function () {
      return this.status == ProgressStatusType.loadingMetadata
    },
    formattedProgressBarText: function () {
      const customBarText = this.customText?.[this.status]

      if (this.status == ProgressStatusType.downloading) {
        return customBarText ?? `${this.formattedProgress}`;
      }

      if (this.status == ProgressStatusType.success) {
        return customBarText ?? ProgressStatusTextMap[this.status];
      }

      if (this.status == ProgressStatusType.extracting) {
        return customBarText ??  `解压中 ${this.formattedProgress}`;
      }

      return customBarText ?? ProgressStatusTextMap[this.status];
    },
    percentage: function () {
      if (this.status == ProgressStatusType.loadingMetadata) {
        return 100;
      }

      if (this.downloadProgress.totalLength == 0) {
        return 0;
      }

      return Math.round(
        (this.downloadProgress.currentLength /
          this.downloadProgress.totalLength) *
          100
      );
    },
    barVariant: function () {
      return ProgressStatusBarVariantMap[this.status];
    },
    status: function () {
      if (
        this.downloadProgress.currentLength == 0 &&
        this.downloadProgress.status == statusType.downloading
      ) {
        return ProgressStatusType.loadingMetadata;
      }

      return this.downloadProgress.status;
    },
    formattedCurrentLength: function () {
      return filesize(this.downloadProgress.currentLength, { round: 2 });
    },
    formattedTotalLength: function () {
      return filesize(this.downloadProgress.totalLength, { round: 2 });
    },
    formattedDownloadSpeed: function () {
      return filesize(this.downloadProgress.downloadSpeed, { round: 2 }) + "/s";
    },
    formattedProgress: function () {
      return `${this.formattedCurrentLength} / ${this.formattedTotalLength} (${this.formattedDownloadSpeed})`;
    },
  },
};
</script>

<template>
  <b-card class="mb-3" v-if="'links' in game_info" title-tag="h5" title="链接">
    <span v-for="(value, key) in game_info.links" :key="key">
      <a v-bind:href="value" class="card-link" target="_blank" rel="noopener">
        {{ key }}
      </a>
      <br />
    </span>
  </b-card>
</template>

<script>
export default {
  name: "LinksCard",
  props: ["game_info"],
};
</script>
